import { z } from "zod"

/**
 * Specify your server-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars.
 */
const server = z.object({
  NODE_ENV: z.enum(["development", "test", "production"]),
  IGNORE_LINTING: z.string().optional(),
})

/**
 * Specify your client-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars. To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
  NEXT_PUBLIC_SN_API_URL: z.string().min(1),
  NEXT_PUBLIC_SN_APP_ENV: z.enum([
    "local",
    "development",
    "staging",
    "demo",
    "production",
  ]),
  NEXT_PUBLIC_LIVEBLOCKS_PUBLIC_KEY: z.string().min(1),
  NEXT_PUBLIC_AUTH_URL: z.string().min(1),
  NEXT_PUBLIC_GOOGLE_FONTS_API_KEY: z.string().min(1),
  NEXT_PUBLIC_FULLSTORY_ORG_ID: z.string().min(1).optional(),
  NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID: z.string().min(1),
  NEXT_PUBLIC_AUTH_DEBUG: z.string().max(1).optional(),
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: z.string().min(1),
  NEXT_PUBLIC_SEGMENT_API_HOST: z.string().min(1),
})

/**
 * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
 * middlewares) or client-side so we need to destruct manually.
 *
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  NODE_ENV: process.env.NODE_ENV,
  IGNORE_LINTING: process.env.IGNORE_LINTING,
  NEXT_PUBLIC_SN_API_URL: process.env.NEXT_PUBLIC_SN_API_URL,
  NEXT_PUBLIC_SN_APP_ENV: process.env.NEXT_PUBLIC_SN_APP_ENV,
  NEXT_PUBLIC_LIVEBLOCKS_PUBLIC_KEY:
    process.env.NEXT_PUBLIC_LIVEBLOCKS_PUBLIC_KEY,
  NEXT_PUBLIC_AUTH_URL: process.env.NEXT_PUBLIC_AUTH_URL,
  NEXT_PUBLIC_GOOGLE_FONTS_API_KEY:
    process.env.NEXT_PUBLIC_GOOGLE_FONTS_API_KEY,
  NEXT_PUBLIC_FULLSTORY_ORG_ID: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID,
  NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID:
    process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
  NEXT_PUBLIC_AUTH_DEBUG: process.env.NEXT_PUBLIC_AUTH_DEBUG,
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  NEXT_PUBLIC_SEGMENT_API_HOST: process.env.NEXT_PUBLIC_SEGMENT_API_HOST,
}

// Don't touch the part below
// --------------------------

const merged = server.merge(client)

/** @typedef {z.input<typeof merged>} MergedInput */
/** @typedef {z.infer<typeof merged>} MergedOutput */
/** @typedef {z.SafeParseReturnType<MergedInput, MergedOutput>} MergedSafeParseReturn */

let env = /** @type {MergedOutput} */ (process.env)

if (!!process.env.SKIP_ENV_VALIDATION == false) {
  const isServer = typeof window === "undefined"

  const parsed = /** @type {MergedSafeParseReturn} */ (
    isServer
      ? merged.safeParse(processEnv) // on server we can validate all env vars
      : client.safeParse(processEnv) // on client we can only validate the ones that are exposed
  )

  if (parsed.success === false) {
    console.error(
      "❌ Invalid environment variables:",
      parsed.error.flatten().fieldErrors
    )
    throw new Error("Invalid environment variables")
  }

  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== "string") return undefined
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isServer && !prop.startsWith("NEXT_PUBLIC_"))
        throw new Error(
          process.env.NEXT_PUBLIC_SN_APP_ENV === "production"
            ? "❌ Attempted to access a server-side environment variable on the client"
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`
        )
      return target[/** @type {keyof typeof target} */ (prop)]
    },
  })
}

export { env }
