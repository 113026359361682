import { env } from "@supernovaio/cloud/env.mjs"

/*
 * Environments supported by the Cloud app.
 * Additional info: https://www.notion.so/supernovaio/Cloud-Environments-4c5ba0b90d8b44b18bf68bde8df9d145
 */
export enum AppEnvironments {
  local = "local",
  development = "development",
  staging = "staging",
  demo = "demo",
  production = "production",
}

export const APP_ENVIRONMENT = env.NEXT_PUBLIC_SN_APP_ENV as AppEnvironments

// TODO: temporary workaround, will be replaced with Feature flags
export const isProduction =
  APP_ENVIRONMENT === AppEnvironments.demo ||
  APP_ENVIRONMENT === AppEnvironments.staging ||
  APP_ENVIRONMENT === AppEnvironments.production
